body div.menu_dialog {
  width: 100vw;
  bottom: 0;
  background: radial-gradient(100.26% 137.49% at 20.64% 0%, #9C0303 -30%, #3C102F 68.14%, #150B21 100%), #000;
  overflow: hidden;
  animation: slideInFromTop 0.3s ease-out;

  [data-reach-dialog-content] {
    background: transparent;
    padding: 80px 0 0;
    width: 100%;
    margin: 0;
  }

  .header .header_inner {
    background-color: transparent !important;
  }

  .menu_dialog_title {
    margin-bottom: 24px;
    padding: 0 16px;
    font-size: 48px;
    font-family: Poppins;
    color: #fff;
    opacity: 0.8;
    font-weight: 600;
  }
}

@keyframes slideInFromTop {
  from {
    opacity: 0;
    // transform: translateY(-100%);
  }

  to {
    // transform: translateY(0);
    opacity: 1;
  }
}


@media screen and (max-width: 400px) {
  body div.menu_dialog {
    .menu_dialog_title {
      font-size: 32px;
      margin-bottom: 14px;
    }
  }
}