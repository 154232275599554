.manifesto {
  position: relative;

  &::after {
    content: "";
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 20%;
    z-index: 2;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
  }

  .manifesto_bg {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;

  }

  .manifesto_inner {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    left: 0;
    text-align: center;

    h2 {
      font-size: 48px;
      text-transform: uppercase;
    }

    .manifesto_p1,
    .manifesto_p2 {
      font-size: 20px;
      font-weight: 400;
      opacity: 0.8;
      max-width: 730px;
    }

    .manifesto_p1 {
      padding: 60px 0 30px;
    }

    .manifesto_btn {
      margin-top: 90px;
      color: #000;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 191px;
        height: 54px;
        opacity: 0.5;
        background-color: #CC2BE6;
        filter: blur(30px);

      }

      .manifesto_btn_inner {
        width: 280px;
        height: 52px;
        position: relative;
        z-index: 2;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .manifesto {
    .manifesto_bg {
      width: 200%;
      left: -50%;
    }

    .manifesto_inner {
      .manifesto_p1 {
        padding: 32px 0 21px;
      }

      .manifesto_p1,
      .manifesto_p2 {
        font-size: 14px;

      }

      .manifesto_p2 {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .manifesto {
    .manifesto_inner {
      h2 {
        font-size: 18px;
      }

      .manifesto_p1 {
        padding: 20px 0 16px;
      }

      .manifesto_p1,
      .manifesto_p2 {
        font-size: 12px;

      }

      .manifesto_btn {
        margin-top: 30px;

        .manifesto_btn_inner {
          width: 200px;
          margin: 0 auto;
        }
      }
    }
  }
}