.btn {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.20);
  padding: 3px;
  .btnInner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: rgba(255, 255, 255, 0.80);
    transition: all 0.3s ease;
    border-radius: 8px;
    &:hover{ 
      background-color: #fff;
    }
  }
  
}