.resource_banner {
  border-radius: 16px;
  // background: linear-gradient(99deg, #0F8FC6 3.54%, #1E57EA 34.35%, #02022A 100%);
  position: relative;
  overflow: hidden;
  padding: 70px 144px 70px 80px;
  margin-bottom: 24px;

  .resource_bg1 {
    top: 0;
    left: 52%;
    transform: translateX(-50%);
    background: #0d29b8;
    width: 40%;
    height: 100%;
    position: absolute;
    border-radius: 136px;
    filter: blur(59px);
    z-index: 3;
    opacity: 0.9;
  }

  .resource_banner_inner {
    position: relative;
    z-index: 2;
    display: flex;
    gap: 60px;
    align-items: center;
    justify-content: flex-start;
  }

  .resource_banner_fl {
    flex: 3;
    position: relative;

  }

  .resource_banner_fr {
    flex: 2;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 29px;
  }

  .resource_banner_fr_img {
    width: 64px;
  }

  .resource_banner_fr_title {
    font-size: 20px;
    font-weight: 600;
  }

  .resource_banner_fr_content {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.8;
    padding-bottom: 20px;
  }

  .resource_banner_bg {
    background-image: url("https://static-dev.zypher.game/img/zypher/resources_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    // background-size: 100% 100%;
    // mix-blend-mode: soft-light;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .resource_banner_seacher {
    padding: 13px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 13px;
    border: 0.84px solid rgba(255, 255, 255, 0.19);
    background: radial-gradient(2562.92% 111.2% at 16.56% 0%, rgba(187, 41, 211, 0.20) 0%, rgba(82, 8, 94, 0.00) 100%), rgba(13, 14, 39, 0.60);
    box-shadow: 13px 13px 40.333px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(13px);
    gap: 13px;
    margin-bottom: 12px;

    img {
      border-radius: 10.083px;
      border: 0.84px solid rgba(255, 255, 255, 0.10);
      background: rgba(255, 255, 255, 0.10);
      padding: 8px;
    }

    p {
      opacity: 0.3;
      font-size: 16px;
    }
  }

  .resource_banner_card {
    overflow: hidden;
    border-radius: 13px;
    border: 1x solid rgba(255, 255, 255, 0.19);
    background: radial-gradient(165% 100% at 21.86% 4.43%, rgba(247, 51, 204, 0.20) 0%, rgba(46, 4, 37, 0.00) 100%), rgba(13, 14, 39, 0.60);
    box-shadow: 13px 13px 40px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(13px);
    padding-top: 20px;

    .resource_banner_title {
      padding: 0 20px;
      opacity: 0.8;
      font-size: 12px;
      padding-bottom: 10px;
    }

    ul {
      padding: 0 20px;

      li {
        padding: 10px 0;
        display: flex;
        gap: 15px;
        align-items: center;

        img {
          display: block;
          width: 40px;
          height: 40px;
        }

        .resource_banner_tit {
          font-size: 16px;
          padding-bottom: 10px;
        }

        .resource_banner_content {
          font-size: 12px;
          line-height: 12px;
          opacity: 0.5;
        }
      }
    }

    .resource_banner_bottom {
      padding: 15px 20px;
      background-color: rgba(0, 0, 0, 0.30);
      box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset;
      backdrop-filter: blur(18px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      opacity: 0.5;

      img {
        display: block;
        height: 22px;
      }
    }
  }
}

.resources_nav {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: stretch;

  .resources_nav_ul {
    flex: 1;
    width: 33.3%;
    display: flex;
    justify-content: space-between;
    gap: 24px;
    align-items: stretch;
    flex-direction: column;

    .resources_nav_li {
      border-radius: 16px;
      background-color: #16111E;

      &:last-child {
        flex: 1;
      }
    }
  }

  .resource_nav_img {
    padding: 40px 40px 0;
    display: block;
    width: 64px;
  }

  .resource_nav_title {
    padding: 29px 40px;
    font-weight: 600;
    font-size: 20px;
  }

  .resource_nav_content {
    padding: 0 40px 40px;
    font-size: 16px;
    opacity: 0.8;
    font-weight: 400;
  }
}

.resources_nav1_list {
  display: flex;
  gap: 16px;
  padding: 0 40px 100px;
  flex-wrap: wrap;

  img {
    display: block;
    width: 60px;
  }
}

.img_nav_big {
  display: block;
  width: 100%;
  margin-top: -10px;
}

.resources_nav_dev {
  flex: 1;
  padding: 1px;
  border-radius: 16px;
  overflow: hidden;
  background: radial-gradient(120% 100% at 20% 5%, rgba(214, 42, 171, 0.20%), rgba(224, 170, 170, 0.77), #000 80%);

  .resources_nav_dev_inner {
    border-radius: 16px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    gap: 29px;
    background: radial-gradient(90.43% 107.89% at 20.53% 14.18%, #9C0303 0%, #240C29 72%, #05010E 100%);
    padding: 0 40px;
    text-align: center;

    h4 {
      padding-top: 20px;
      font-family: Poppins;
      font-size: 36px;
      line-height: 36px;
      font-weight: 600;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      opacity: 0.8;
    }

    .resources_nav_btn {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 191px;
        height: 54px;
        opacity: 0.3;
        background-color: #CC2BE6;
        filter: blur(30px);
      }

    }

    .resources_nav_btn_inner {
      padding: 8px 60px;
      line-height: 28px;
      position: relative;
      z-index: 2;

      p {
        opacity: 1;
        font-weight: 500;
      }

      .resources_nav_btn_arrow {
        opacity: 0.5;

        path {
          stroke: #000;
        }
      }
    }
  }
}

@media screen and (max-width: 830px) {
  .resources {
    .home_title {

      h2,
      p {
        width: 100%;
      }
    }
  }

  .resource_banner {
    padding: 24px;

    .resource_banner_inner {
      flex-direction: column-reverse;
      gap: 30px;
      align-items: normal;
    }

    .resource_bg1 {
      bottom: 0;
      right: 0;
      transform: none;
      width: 20%;
      opacity: 1;
      left: auto;
    }
  }

  .resources_nav {
    flex-direction: column;

    .resources_nav_ul {
      width: 100%;
    }

    .resource_nav_img {
      padding: 24px 24px 0;
    }

    .resource_nav_title {
      padding: 29px 24px;
    }

    .resource_nav_content {
      padding: 0 24px 29px;
      font-size: 14px;
    }

    .resources_nav1_list {
      padding: 0 24px 60px;
    }
  }

  .resources_nav_dev {
    .resources_nav_dev_inner {
      padding: 48px 8px;

      h4 {
        font-size: 32px;
        line-height: 32px;
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .resource_banner {
    padding: 16px;

    .resource_banner_card {
      padding-top: 16px;

      .resource_banner_bottom {
        font-size: 12px;
        padding: 12px 10px;

        img {
          height: 16px;
        }
      }

      .resource_banner_title {
        padding: 0 10px;
      }

      ul {
        padding: 0 10px;

        li {
          gap: 8px;

          img {
            width: 30px;
            height: 30px;
          }

          .resource_banner_tit {
            font-size: 14px;
            padding: 0;
          }
        }
      }
    }

    .resource_banner_inner {
      flex-direction: column-reverse;
      gap: 10px;
      align-items: normal;
    }

    .resource_bg1 {
      bottom: 0;
      right: 0;
      transform: none;
      width: 20%;
      opacity: 1;
      left: auto;
    }

    .resource_banner_fr {
      gap: 12px;
    }

    .resource_banner_fr_img {
      width: 40px;
    }

    .resource_banner_seacher {
      padding: 6px;

      p {
        font-size: 12px;
      }

      img {
        padding: 2px;
      }
    }

    .resource_banner_fr_content {
      font-size: 12px;
      line-height: 20px;
    }
  }

  .resources_nav {
    flex-direction: column;

    .resources_nav_ul {
      width: 100%;
    }

    .resource_nav_img {
      padding: 10px 10px 0;
      width: 44px;
    }

    .resource_nav_title {
      padding: 10px;
      font-size: 14px;
    }

    .resource_nav_content {
      padding: 0 10px 19px;
      font-size: 12px;
    }

    .resources_nav1_list {
      padding: 0 10px 30px;
      gap: 10px;

      img {
        width: 40px;
      }
    }
  }

  .resources_nav_dev {
    .resources_nav_dev_inner {
      padding: 48px 8px;

      h4 {
        font-size: 20px;
        line-height: 24px;
      }

      p {
        font-size: 14px;
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}