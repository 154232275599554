.social_li1,
.social_li2 {
  border-radius: 16px;
  padding: 1px;

  .social_inner {
    padding: 64px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
  }
}

.social_inner_fl {
  h3 {
    font-size: 32px;
    margin-bottom: 24px;
    line-height: 48px;
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    opacity: 0.8;
    font-weight: 400;
    line-height: 26px;
  }
}

.social_li1 {
  background: radial-gradient(120% 100% at 20% 5%, rgba(115, 94, 148, 0.2), rgba(145, 144, 146, .77), #000 80%);

  .social_inner {
    background: radial-gradient(160% 80% at 40% 10%, #231D2D, #000 90%);
  }
}

.social_li2 {
  margin-top: 48px;
  background: radial-gradient(120% 100% at 20% 5%, rgba(115, 94, 148, 0.2), rgba(145, 144, 146, .77), #000 80%);

  .social_inner {
    background: radial-gradient(400.43% 250.89% at 20.53% 5.18%, #9c0303, #27011a 24%, #000 38%);
    // background: radial-gradient(50.43% 107.89% at 20.53% 14.18%, #9C0303 0%, rgba(22, 17, 30, 0.00) 100%);
    // background: radial-gradient(90.43% 80% at 10% 14%, #9C0303 0%, #0b0416 100%);
  }

  .social_inner_fl {
    flex: 2;
  }
}

.social_community {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 42px;

  a {
    display: block;

    svg {
      display: block;
      width: 44px;
      height: 44px;
    }
  }
}

.input_box {
  display: flex;
  gap: 16px;
  flex: 1;
  align-items: stretch;
  justify-content: flex-end;

  .input_box_input {
    position: relative;
    z-index: 2;
    flex: 1;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05);

    input {
      height: 100%;
      padding: 16px;
    }
  }

  .subscribe_btn {
    position: relative;

    // .dots {
    //   color: #000;
    // }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 191px;
      height: 54px;
      opacity: 0.3;
      background-color: #CC2BE6;
      filter: blur(30px);
    }
  }

  .subscribe_btn_inner {
    position: relative;
    z-index: 2;
    padding: 0 60px;
    height: 50px;
    gap: 0;
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
input[type="url"],
input[type="search"] {
  padding: 0;
  margin: 0;
  border: none;

  font-size: inherit;
  line-height: inherit;
  color: inherit;

  text-decoration: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-color: transparent;

  outline: none;
  box-sizing: border-box;
}

input[placeholder]::placeholder {
  color: rgba(255, 255, 255, 0.3)
}

input {
  width: 100%;
  height: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 768px) {

  .social_li1,
  .social_li2 {
    .social_inner {
      padding: 64px 16px;
      flex-direction: column;
      text-align: center;
    }
  }

  .social_inner_fl {
    h3 {
      font-size: 28px;
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 24px;
    }
  }

  .social_community {
    gap: 24px;
  }

  .input_box {
    flex-direction: column;

    .input_box_input {
      width: 100%;
      min-width: 320px;
    }

    .subscribe_btn {
      width: 100%;
      margin: 0 auto;
    }

    .subscribe_btn_inner {
      min-height: 52px;
    }
  }
}

@media screen and (max-width: 300px) {

  .social_li1,
  .social_li2 {
    .social_inner {
      padding: 24px 10px;
    }
  }

  .social_inner_fl {
    h3 {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 12px;
    }

    p {
      margin-bottom: 12px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .social_community {
    gap: 12px;
  }

  .input_box {
    flex-direction: column;

    .input_box_input {
      min-width: 200px;
    }

    .subscribe_btn {
      width: 50%;
      margin: 0 auto;
    }

    .subscribe_btn_inner {
      min-height: 52px;
    }
  }
}