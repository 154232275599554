.solutions_nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  align-items: stretch;

  // flex-wrap: wrap;
  .solutions_nav_item {
    position: relative;
    display: block;
    flex: 1;
    // width: 33.3%;
    max-width: 320px;
    border-radius: 16px;
    background: #201C26;
    transition: all 0.3s ease;
    position: relative;
    color: #fff;
    font-size: 14px;
    line-height: 25px;
    overflow: hidden;
    padding: 1px;


    .solutions_nav_item_inner {
      padding: 40px 40px 80px;
      border-radius: 16px;
      height: calc(100% - 40px - 80px);
      position: relative;
      z-index: 2;
    }

    &:hover {
      // background-color: transparent;
      // background-image: radial-gradient(90.43% 80% at 10% 14%, #e66767 0%, #0b0416 100%);

      // .solutions_nav_item_inner {
      //   background: radial-gradient(90.43% 107.89% at 20.53% 14.18%, #9C0303 0%, rgba(87, 49, 148, 0.00) 100%);
      // }
      .background-animation {
        animation-name: backgroundTransitionReverse;
      }

      .solutions_more {
        opacity: 1;
      }
    }
  }

  .solutions_title {
    font-size: 24px;
    padding-bottom: 29px;
    font-weight: 600;
  }

  .solutions_content {
    font-size: 14px;
    opacity: 0.8;
  }

  .solutions_more {
    position: absolute;
    bottom: 40px;
    left: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    opacity: 0.5;
    transition: all 0.3s ease;

    img {
      margin-left: 4px;
    }
  }
}

.background-animation {
  background-color: #201C26;
  background-image: radial-gradient(circle at 10.53% 10.18%, #9C0303 00%, #2F0E3B 60%);
  animation-name: backgroundTransition;
  animation-fill-mode: forwards;
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.1, 1, 0.25, 1);
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@keyframes backgroundTransition {
  0% {
    background-color: #201C26;
    opacity: 1;
  }

  100% {
    background-color: transparent;
    opacity: 0;
  }
}



@keyframes backgroundTransitionReverse {
  0% {
    background-color: transparent;
    opacity: 0;
  }

  100% {
    background-color: #201C26;
    opacity: 1;
  }
}

@media screen and (max-width: 830px) {
  .solutions_nav {
    flex-direction: column;
    gap: 16px;

    .solutions_nav_item {
      max-width: 100%;

      .solutions_nav_item_inner {
        padding: 24px;
      }
    }

    .solutions_title {
      padding-bottom: 16px;
      font-size: 20px;
      font-weight: 600;
    }

    .solutions_more {
      padding-top: 16px;
      position: relative;
      bottom: auto;
      left: 0;
    }
  }
}

@media screen and (max-width: 300px) {
  .solutions_nav {
    .solutions_nav_item {
      .solutions_nav_item_inner {
        padding: 16px 16px 20px;
        height: calc(100% - 16px - 20px);
      }
    }

    .solutions_title {
      font-size: 14px;
      padding-bottom: 12px;
    }

    .solutions_content,
    .solutions_more {
      font-size: 12px;
      line-height: 18px;
    }

  }
}