.commuinty {
  a {
    .community_svg {
      path {
        transition: all 0.3 ease;
      } 
    }
    &:hover {
      .community_svg {
        path {
          fill-opacity: 1;
        }
      }
    }
  }
}