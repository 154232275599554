.header {
  height: 168px;

  .header_inner {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: calc(100% - 80px);
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    font-weight: 500;
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
  }

  .header_logo {
    display: block;
    width: 166px;
    scroll-behavior: auto;

    img {
      display: block;
      width: 100%;
    }
  }

  .header_iinner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 82px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;

    .home_nav {
      opacity: 0.5;
      display: block;
      color: #fff;

      &:hover {
        opacity: 1;
      }

      &.home_nav_on {
        opacity: 1;
      }
    }
  }

  .header_fr {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 60px;
    font-size: 14px;

    .explore_games_btn_svg {
      width: 24px;
      height: 24px;
    }

    .explore_games_btn {
      width: 166px;
      height: 40px;
      line-height: 40px;
      color: rgba(255, 255, 255, 0.50);
      padding: 0;
      background: rgba(255, 255, 255, 0.05);
      font-weight: 400;

      .explore_games_btn_inner {
        background-color: transparent;
      }

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    .header_inner {
      width: calc(100vw - 32px);
      padding: 0 16px;
      overflow: hidden;

      .header_logo {
        width: 133px;
      }

      .header_fr {
        gap: 16px;
      }
    }
  }
}