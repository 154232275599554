.home_bg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;

  .home_bg1,
  .home_bg2 {
    width: 260px;
    height: 453px;
    transform: rotate(-53.467deg);
    position: absolute;
    border-radius: 453px;
    mix-blend-mode: plus-lighter;
    filter: blur(200px);
    animation-fill-mode: both;
    animation-name: bg1;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
  }

  .home_bg1 {
    top: 300px;
    left: 38%;
    transform: translateX(-50%);
    background: #FF0A28;
  }

  .home_bg2 {
    top: 150px;
    left: 68%;
    transform: translateX(-50%);
    background: #9F0AE5;
  }
}

@media screen and (max-width: 1000px) {
  .home_bg {

    .home_bg1,
    .home_bg2 {
      width: 200px;
      height: 400px;
      animation: none;
      filter: blur(150px);
    }

    .home_bg1 {
      left: 0%;
      transform: translateX(0);
    }

    .home_bg2 {
      right: 20%;
      left: auto;
      transform: translateX(0);
    }
  }
}

@keyframes bg1 {
  0% {
    width: 260px;
    height: 453px;
    opacity: 1;
  }

  50% {
    width: 360px;
    height: 553px;
    opacity: 0.8;
  }

  100% {
    width: 260px;
    height: 453px;
    opacity: 1;
  }
}