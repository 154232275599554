.home_title {
  text-align: center;
  max-width: 900px;
  margin: 0 auto;

  h2 {
    font-size: 48px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  p {
    opacity: 0.6;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;

    &:last-child {
      padding-bottom: 60px;
    }
  }
}

@media screen and (max-width: 768px) {
  .home_title {
    h2 {
      font-size: 28px;
      width: 80%;
      margin: 0 auto 24px;
    }

    p {
      font-size: 14px;
      line-height: 24px;
      width: 90%;
      margin: 0 auto;

      &:last-child {
        padding-bottom: 24px;
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .home_title {
    h2 {
      font-size: 18px;
      width: 100%;
    }

    p {
      width: 100%;
      font-size: 12px;
      line-height: 18px;
    }
  }
}