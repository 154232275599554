.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 40%;
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 16px;
  background: #000;
  backdrop-filter: blur(10px);
  opacity: 0;
  width: 72px;
  height: 72px;
  font-size: 24px;
  transition: all 0.3s ease;
  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  &.swiper-button-hidden {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
  }
  .swiper-navigation-disabled & {
    display: none !important;
  }
  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform-origin: center;
  }
  .swiper-rtl & svg {
    transform: rotate(180deg);
  }
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 64px;
  right: auto;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 64px;
  left: auto;
}
.swiper-button-lock {
  display: none;
}
/* Navigation font start */
.swiper-button-prev,
.swiper-button-next {
  &:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
  }
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  &:after {
    content: 'prev';
  }
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  &:after {
    content: 'next';
  }
  right: 64px;
  left: auto;
}
/* Navigation font end */
