  .dao_btn {
    margin: -30px auto 60px;
    max-width: 300px;
    height: 52px;
    line-height: 52px;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    .dao_btn {
      margin: -10px auto 40px;
      max-width: 50%
    }
  }

  .dao_nav {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 24px;

    li {
      flex: 1;
      border-radius: 16px;
      background: radial-gradient(circle at 30% 10%, rgba(115, 94, 148, 0.2), rgba(145, 144, 146, .77), #000 80%);

      width: 33.3%;
      padding: 1px;

      .dao_nav_inner {
        border-radius: 16px;
        background: radial-gradient(circle at 30% 10%, #231D2D, #000 80%);
        padding: 64px 40px 160px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
      }

      .dao_nav_img {
        display: block;
        width: 64px;
      }

      .dao_nav_title {
        font-size: 20px;
        line-height: 30px;
        margin: 29px 0;
        font-weight: 600;
      }

      .dao_nav_content {
        font-size: 16px;
        font-weight: 400;
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 830px) {
    .dao_nav {
      flex-direction: column;

      li {
        width: 100%;

        .dao_nav_inner {
          padding: 24px 24px 40px;
        }
      }
    }
  }

  @media screen and (max-width: 300px) {
    .dao_nav {
      li {
        .dao_nav_img {
          width: 44px;
        }

        .dao_nav_title {
          font-size: 14px;
          margin: 12px 0 0;
        }

        .dao_nav_content {
          font-size: 12px;
        }

        .dao_nav_inner {
          padding: 20px 10px 30px;
        }
      }
    }
  }