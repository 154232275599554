// .icon_menu {
//   --color: #fff;
//   width: 18px;
//   height: 24px;
//   margin: 0;
//   outline: none;
//   position: relative;
//   cursor: pointer;
//   -webkit-appearence: none;
//   -webkit-tap-highlight-color: transparent;

//   svg[data-v-5bf3ac00] {
//     top: -6px;
//     left: -14px;
//     stroke: #fff;
//     stroke-width: 3px;
//     stroke-linecap: round;
//     stroke-linejoin: round;
//     fill: none;
//     display: block;
//     position: absolute;
//   }

//   svg path[data-v-5bf3ac00] {
//     transition: stroke-dasharray var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s),
//       stroke-dashoffset var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s);
//     stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
//     stroke-dashoffset: var(--offset, 126px);
//     transform: translateZ(0);
//   }

//   svg path[data-v-5bf3ac00]:nth-child(2) {
//     --duration: 0.7s;
//     --easing: ease-in;
//     --offset: 100px;
//     --array-2: 74px;
//   }

//   svg path[data-v-5bf3ac00]:nth-child(3) {
//     --offset: 133px;
//     --array-2: 107px;
//   }

//   &.active svg path[data-v-5bf3ac00] {
//     --offset: 57px;
//   }

//   &.active svg path[data-v-5bf3ac00]:first-child,
//   &.active svg path[data-v-5bf3ac00]:nth-child(3) {
//     --delay: 0.15s;
//     --easing: cubic-bezier(0.2, 0.4, 0.2, 1.1);
//   }

//   &.active svg path[data-v-5bf3ac00]:nth-child(2) {
//     --duration: 0.4s;
//     --offset: 2px;
//     --array-1: 1px;
//   }

//   &.active svg path[data-v-5bf3ac00]:nth-child(3) {
//     --offset: 58px;
//   }
// }
.icon_menu {
  background: none;
  border: 0;
  width: 20px;
  height: 16px;
  position: relative;

  &.active {
    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }

    span {
      opacity: 0;
    }
  }

  span,
  &::before,
  &::after {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: 0.2s;
    border-radius: 2px;
  }

  &:before {
    top: 0;
    transform-origin: bottom right;
  }

  &:after {
    top: auto;
    bottom: 0;
    transform-origin: top right
  }

  span {
    text-indent: -9999px;
    top: 50%;
    transform: translateY(-50%);
  }
}