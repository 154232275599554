.game_swiper_nav {
  width: 100vw;
  position: fixed;
  height: 88px;
  overflow: hidden;
  top: 80px;
  z-index: 100;
}

.nav_item {
  width: 100vw;
  position: relative;
}

.nav_item_bg {
  position: relative;
  z-index: 1;
  width: 100vw;
  height: 88px;
}

.nav_item_inner {
  width: 90%;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  p {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    line-height: 24px;
  }
}

.nav_item_inner0 {
  color: #fff;

  p {
    i {
      font-weight: 600;
    }
  }
}

.nav_item_inner1 {
  color: #552200
}