.home_section {
  max-width: 1300px;
  margin: 0 auto;
  padding: 110px 0;
  position: relative;
}

@media screen and (max-width: 1360px) {
  .home_section {
    width: calc(100vw - 32px);
    margin: 0 auto;
    padding: 64px 0;
    overflow: hidden;
  }
}

@media screen and (max-width: 300px) {
  .home_section {
    padding: 30px 0;
  }
}