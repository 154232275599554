.evolve_banner {
  position: relative;
  padding: 0 40px;
  margin-bottom: 24px;
  border-radius: 14px;
  // background: linear-gradient(96deg, #F39292 1.56%, #8F00C1 31.83%, #030836 98.75%);

  .evolve_banner_inner {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .evolve_banner_fl,
  .evolve_banner_fr {
    flex: 1;
    position: relative;

  }

  .evolve_banner_fl {
    display: flex;
    flex-direction: column;
    gap: 29px;

    img {
      width: 64px;
    }

    .evolve_banner_title {
      font-size: 20px;
      font-weight: 600;
    }

    .evolve_banner_content {
      font-size: 16px;
      font-weight: 400;
      opacity: 0.8;
      padding-bottom: 20px;
      width: 80%;
    }
  }

  .evolve_banner_bg {
    background-image: url("https://static-dev.zypher.game/img/zypher/evolve_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    // background-size: 100% 100%;
    // mix-blend-mode: multiply;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 16px;
  }

  .evolve_banner_fr_bg {
    display: block;
    width: 100%;
  }

  .evolve_banner_fr_card {
    position: absolute;
    border-radius: 7px;
    backdrop-filter: blur(2px);
    overflow: hidden;
    top: -30px;
    right: 14%;
    width: 194px;

    .evolve_banner_fr_card_img {
      display: block;
      width: 100%;
    }

    .evolve_banner_bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 7px 14px;
      gap: 6px;
      background-color: rgba(255, 255, 255, 0.80);

      .evolve_banner_bottom_avatar {
        display: block;
        width: 28px;
      }

      p {
        font-size: 12px;
        color: #000;
      }
    }
  }
}

.evolve_nav {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 24px;

  li {
    flex: 1;
    padding: 40px 40px 70px;
    border-radius: 16px;
    background: #201C26;
    width: 33.3%;

    .evolve_nav_img {
      display: block;
      width: 64px;
    }

    .evolve_nav_title {
      font-size: 20px;
      line-height: 30px;
      margin: 29px 0;
      font-weight: 600;
    }

    .evolve_nav_content {
      font-size: 16px;
      font-weight: 400;
      opacity: 0.8;
    }
  }
}

@media screen and (max-width: 830px) {
  .evolve {
    .home_title {

      h2,
      p {
        width: 100%;
      }
    }
  }

  .evolve_banner {
    padding: 24px;

    .evolve_banner_fl {
      .evolve_banner_content {
        width: 90%;
        padding-bottom: 50px;
      }
    }

    .evolve_banner_inner {
      flex-direction: column;
    }

    .evolve_banner_fr_card {
      width: 35%;

      .evolve_banner_bottom {
        padding: 2px;

        p {
          font-size: 10px;
        }

        .evolve_banner_bottom_avatar {
          width: 20px;
        }
      }
    }
  }

  .evolve_nav {
    flex-direction: column;

    li {
      width: calc(100% - 48px);
      padding: 24px;
    }
  }
}

@media screen and (max-width: 300px) {
  .evolve_banner {
    padding: 16px 10px;

    .evolve_banner_fl {
      gap: 12px;

      img {
        width: 44px;
      }

      .evolve_banner_title {
        font-size: 14px;
      }

      .evolve_banner_content {
        font-size: 12px;
      }
    }
  }

  .evolve_nav {

    li {
      padding: 16px 10px;

      .evolve_nav_img {
        width: 44px;
      }

      .evolve_nav_title {
        font-size: 14px;
        margin: 12px 0 0;
      }

      .evolve_nav_content {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}