.news {
  position: relative;
  .home_title {
    h2 {
      margin-bottom: 32px;
    }
  }
  
}
.news_nav {
  position: relative;
  .all_media {
    position: absolute;
    right: 0;
    top: -60px;
    display: flex;
    color: #fff;
    font-size: 16px;
    gap: 4px;
    font-family: Poppins;
    font-weight: 400;
    text-transform: none;
  }
  ul{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 24px;
  }
  li {
    flex: 1;
    width: 33.3%;
    position: relative;
    a {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding-bottom: 30px;
    }
    .news_nav_img{
      display: block;
      width: 100%;
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.20);
    }
    .news_nav_title{
      color: #fff;
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
    }
    .news_nav_time {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      opacity: 0.6;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
@media screen and (max-width: 768px){
  .news {
    width: 100%;
    padding: 0;
    .home_title {
      text-align: left;
      h2{ 
        margin: 0 16px 24px;
      }
    }
    .all_media {
      right: 16px;
      top: -50px;
    }
    .news_nav {
      .all_media {
        font-size: 14px;
      }
      ul {
        overflow-y: scroll;
        padding: 0 16px;
      }
      li {
        width: 80vw;
        min-width: 320px;
        a {
          gap: 24px;
        }
        .news_nav_title {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}