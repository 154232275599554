body .partners_swiper {
  margin-bottom: 16px;

  // .partners_swiper_inner_revose {
  //   transform: scaleX(-1) scaleY(-1);
  //   transform-origin: center;
  // }

  // //   &:before,&:after {
  //     content: "";
  //     position: absolute;
  //     height: 100%;
  //     width: 10vw;
  //     top: 0;
  //     z-index: 2;
  //   }
  //   &:before {
  //     background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
  //     left: 0;
  //   }
  //   &:after {
  //     background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
  //     right: 0;
  //   }
}

.partners_bg1,
.partners_bg2 {
  position: absolute;
  height: 100%;
  width: 8vw;
  top: 0;
  z-index: 2;
}

.partners_bg1 {
  background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
  left: 0;
}

.partners_bg2 {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
  right: 0;
}

.partners {
  position: relative;
  padding-bottom: 120px;
  padding-top: 120px;
  width: 100vw;
  overflow: hidden;

  h2 {
    padding: 64px 0;
    font-size: 48px;
    text-transform: uppercase;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);

    em {
      color: #fff;
    }
  }
}



.partners_swiper {
  width: 100%;
  overflow: hidden;
}

.partners_swiper_inner {
  display: flex;
  animation-fill-mode: both;
  animation-name: carousel-animation;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;

  &.partners_swiper_inner_revose {
    animation-name: carousel-animation02;
  }
}

.partners_swiper_item {
  flex: 0 0 88px;
  height: 88px;
  margin-right: 16px;

  img {
    display: block;
    width: 100%;
    border-radius: 44px;
  }
}

@keyframes carousel-animation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }

  100.1% {
    transform: translateX(0);
  }
}

@keyframes carousel-animation02 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }

  100.1% {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 768px) {
  .partners {
    padding-top: 64px;
    padding-bottom: 64px;

    h2 {
      font-size: 28px;
      line-height: 32px;
      padding: 0 0 32px;
      width: 80%;
      margin: 0 auto;
    }
  }

  .partners_swiper_item {
    flex: 0 0 56px;
    height: 56px;

    img {
      display: block;
      width: 100%;
      border-radius: 28px;
    }
  }
}

@media screen and (max-width: 300px) {
  .partners {
    h2 {
      font-size: 18px;
      line-height: 22px;
    }
  }
}