.home_banner {
  height: calc(100vh - 168px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.animate {
    opacity: 1;
  }

  h1,
  h2 {
    font-size: 80px;
    line-height: 80px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .home_banner_title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    line-height: 80px;
    padding-bottom: 16px;

    span {
      font-family: "none";
      font-weight: 600;
      font-size: 60px;
      color: #581FFF;
    }
  }

  h1 {
    // display: inline-block;
    // background: linear-gradient(90deg, #FA3A0F 0.07%, #391CEA 100.07%);
    // background-clip: text;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // color: transparent;
  }

  h2 {
    opacity: 0.6;
  }

  .home_banner_text {
    max-width: 688px;
    font-size: 20px;
    line-height: 35px;
    padding: 48px 0;
  }

  .home-banner-btn {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    width: 400px;

    .explore_games_btn {
      margin-bottom: 30px;
      width: 45%;
      height: 52px;
      line-height: 52px;
      text-align: center;
    }

  }
}


@media screen and (max-width: 768px) {
  .home_banner {

    .home_banner_title,
    h2 {
      width: 80%;
      margin: 0 auto;
    }

    h1,
    h2 {
      white-space: wrap;
    }

    h1 {
      font-size: 55px;
      line-height: 55px;
      height: 180px;
      margin-top: 100px;
    }

    h2 {
      font-size: 32px;
      line-height: 35px;
    }

    .home_banner_text {
      font-size: 16px;
      line-height: 26px;
      width: 95%;
      margin: 0 auto;
    }

    .explore_games_btn {
      width: 70%;
      margin: 0 auto 30px;
    }
  }
}

@media screen and (max-width: 300px) {
  .home_banner {
    h1 {
      font-size: 40px;
      line-height: 40px;
      height: 180px;
      width: 100%;
    }

    h2 {
      font-size: 20px;
      line-height: 20px;
    }

    .home_banner_text {
      font-size: 13px;
      line-height: 20px;
    }

    .explore_games_btn {
      width: 70%;
      margin: 0 auto 30px;
    }
  }
}