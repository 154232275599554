.home_p {
  position: relative;
  z-index: 2;
  width: 100vw;
  overflow: hidden;
}

body {
  scroll-behavior: smooth;
}

:focus-visible {
  outline: none;
}

@keyframes slide-in-from-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-from-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-from-top {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-from-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.element-from-left,
.element-from-right,
.element-from-top,
.element-from-bottom {
  animation-duration: 1s;
  animation-fill-mode: both;
}

body .element-from-left {
  animation-name: slide-in-from-left;
}

body .element-from-right {
  animation-name: slide-in-from-right;
}

body .element-from-top {
  animation-name: slide-in-from-top;
}

body .element-from-bottom {
  animation-name: slide-in-from-bottom;
}

.animate {
  opacity: 0;
}